import { Outlet, NavLink } from "react-router-dom";
import { useRef, useEffect } from "react";
import gsap from 'gsap'
import keyboard from '../../images/keyboard.jpg'

export const HomeNav = () => {

  const ref = useRef(null)

  useEffect(()=>{
    gsap.set(ref.current,{backgroundImage:`url(${keyboard})`})
  },[])

  return (
    <div className="min-h-screen my-44" ref={ref}>
      <ul className="homeNav">
        <NavLink className="homeNavLink" to="/">Services</NavLink>
        <NavLink className="homeNavLink" to="about">About</NavLink>
      </ul>
      <Outlet/>
    </div>
  );
}