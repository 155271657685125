import { Link } from "react-router-dom"
import { useRef } from "react"

export const PageNumberNavigation = ({basePageURL, pageLimit, numberOfButtons, pageNumberText}) => {

  //pageIndex is for getting results from an array. pageNumber is for any user-readable information. pageNumberText should be a string from the react router url params
  const pageNumber = parseInt(pageNumberText)
  const pageIndex = pageNumber - 1

  //simplified versions for small screens
  if(window.innerWidth < 768){
    numberOfButtons = 3
  }
  let numberButtons = false
  if (window.innerWidth > 500){
    numberButtons = true
  }

  const pageButtons = useRef([]) 
  const previousButton = useRef(null)
  const nextButton = useRef(null)

  return(
    <div className="flex justify-between w-1/2 mx-auto">
          {pageNumber !== 1 && 
            <Link ref={previousButton} className="pageButton pageButtonStep" 
            to={`${basePageURL}/${pageNumber - 1}`}>{"<"}</Link>
          }
          {pageNumber === 1 &&
            <span className="pageButtonDisabled pageButtonStep">{"<"}</span>
          }
          {numberButtons &&
            <div className="w-3/4 flex justify-center space-x-1">
              {
                (() => {
                  const linkArray = []
                  for(let i = -Math.floor(numberOfButtons / 2) + pageNumber; i <= Math.floor(numberOfButtons / 2) + pageNumber; i++) {
                    if(i >= 0 && i <= pageLimit - 1) {
                      if(i === pageIndex){
                        linkArray.push(
                          <div className="pageButtonSelected"
                            ref={link => pageButtons.current.push(link)} 
                            key={i}>
                            {i + 1}
                          </div>)
                      } else {
                        linkArray.push(
                          <Link className="pageButton"
                            ref={link => pageButtons.current.push(link)} 
                            to={`${basePageURL}/${i + 1}`}
                            key={i}>
                            {i + 1}
                          </Link>)
                      }
                    } else {
                      linkArray.push(
                      <Link className="pageButton hidden"
                        ref={link => pageButtons.current.push(link)} 
                        to={`${basePageURL}/${i + 1}`}
                        key={i}>
                        {i + 1}
                      </Link>)
                    }
                  }
                return linkArray})()
              }
            </div>
          }
          {pageNumber !== pageLimit && 
            <Link ref={nextButton} className="pageButton pageButtonStep" 
            to={`${basePageURL}/${pageNumber + 1}`}>{">"}</Link>
          }
          {pageNumber === pageLimit &&
            <span className="pageButtonDisabled pageButtonStep">{">"}</span>
          }
        </div>
  )
}