import { useEffect, useCallback } from "react"

import sanityClient from '../client'

import { searchSlugsForSinglePage } from "../services/sanityQueries"

export const useSanityToGetPreviewsForAPage = (slugs, startAt, pageData, setPageData, pageNumber, itemsPerPage) => {
  
  const handleNewPageData = useCallback((sanityResult)=>{
    const pageDataCopy = {...pageData}
    pageDataCopy[pageNumber] = sanityResult
    setPageData(pageDataCopy)
  },[pageNumber, pageData, setPageData])
  
  useEffect(() => {
    if (!pageData[pageNumber] && slugs.length >= 1) {
      (async () => {
        const sanityResult = await sanityClient.fetch(
          searchSlugsForSinglePage(slugs, startAt, itemsPerPage)
        )
        handleNewPageData(sanityResult)
      })()
    }
  },[handleNewPageData, pageNumber, pageData, slugs, startAt, itemsPerPage])
}