import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { Copy } from '../icons/Copy'
import { useRef } from 'react'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'
export const CodeSerializer = (props) => {

  const copyMesage = useRef()

  const handleCopy = async () => {
    await navigator.clipboard.writeText(props.node.code)
    copyMesage.current.classList.replace('hidden', 'block')
    setTimeout(()=>{
      try {
      copyMesage.current.classList.replace('block', 'hidden')
      } catch {
        //unmount error catch
      }
    }, 3000)
  }

  return (
    <div className="py-2">
      <p ref={copyMesage}
        className="bg-rightGreenA text-lg bg-opacity-40 text-rightGreenB border-2 text-center py-1 my-2 font-bold hidden">
        Code Copied
      </p>
      <div onClick={handleCopy} className='cursor-pointer'>
        <div className="flex justify-end">
          <div className="pr-2 pt-3 absolute">
            <Copy/>
          </div>
        </div>
        <SyntaxHighlighter
          language={props.node.language}
          style={vscDarkPlus}>
          {props.node.code}
        </SyntaxHighlighter>
      </div>
    </div>
  )
}