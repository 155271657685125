import { useEffect } from "react";
import sanityClient from '../client'

export const useSanityToGetSlugs = (slugType, fetchThisManySlugs, setBlogSlugs) => {

  const generalPostQuery = (type, limit) => {
    return(
      `*[_type == '${type}'] | order(_createdAt desc){
        slug{
          current
        },
        }[0...${limit}]`
    )
  }

  useEffect(() => {
    sanityClient.fetch(generalPostQuery(slugType, fetchThisManySlugs)).then(response => {
      setBlogSlugs(response.map(post => post.slug.current))
    })
  },[slugType, fetchThisManySlugs, setBlogSlugs])
}