import {Routes, Route, useParams} from 'react-router-dom';
import { useState, useEffect } from "react"
import  sanityClient  from './../../client'
import { searchQueryAll } from "../../services/sanityQueries"
import Fuse from 'fuse.js'
import Helmet from 'react-helmet'
import { SearchPage } from './SearchPage';


export const Search = () => {
  
  const [fuseSearch, setFuseSearch] = useState(null)
  const [pageData, setPageData] = useState({}) //holds data after each page is fetched


  const params = useParams()

  useEffect(()=>{
    (async () => {
      const queryAll = await sanityClient.fetch(searchQueryAll)
      const options = {
        includeScore:true,
        keys:[
          {
            name:'title',
            weight:3
          },
          {
            name:'tags',
            weight:1
          }
        ]
      }
      const fuse = new Fuse(queryAll, options)
      setFuseSearch(
        fuse.search(params.searchQuery)
          .map(result => result.item.slug.current)
        )
    })()
  },[params.searchQuery])
  return(
    <>
      <Helmet>
        <title>Search: {params.searchQuery}</title>
      </Helmet>
        {fuseSearch &&
          <Routes>
            <Route path="/:pageNumber"
            element={<SearchPage fuseSearch={fuseSearch} pageData={pageData} setPageData={setPageData}/>} />
          </Routes>
        }
    </>
  )
}