import { useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import  gsap  from "gsap"
import BlockContent from "@sanity/block-content-to-react"

export const PostPreview = ({post}) => {
  const backgroundBox = useRef(null)
  const textBox = useRef(null)

  useEffect(() => {
    if(backgroundBox.current){
      gsap.set(backgroundBox.current, {backgroundImage:`url(${post.mainImage.asset.url})`})
    }
    if(textBox.current){
      gsap.set(textBox.current,
        {
          backgroundColor:post.backgroundColor,
          color:post.textColor,
        })
    }
  }, [post])

  const onMouseEnter = () => window.innerWidth > 768 && gsap.to(backgroundBox.current, {xPercent:5, ease:"power2.out", duration:0.3})

  const onMouseLeave = () => window.innerWidth > 768 && gsap.to(backgroundBox.current, {xPercent:0, ease:"power2.in", duration:0.3})

  return(
    <>
      {post &&
        <div className="md:w-3/4 mx-2" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <Link to={`/${(() => post._type === 'project' ? 'projects' : 'blog')()}/view/${post.slug.current}`}>
            <div ref={backgroundBox} className="bg-cover min-h-[22rem]">
              <div ref={textBox} className={`backdrop-blur-sm textTierBase font-medium paddingLevelOne min-h-[11rem]`}>
                <div className="flex justify-between">
                  <h2 className="font-extrabold textTierExtraLarge underline pb-2">{post.title}</h2>
                  <h3 className="font-medium textTierLarge">{(() => post._type === 'project' ? `${post.projectType} project` : ``)()}</h3>
                </div>
                <BlockContent blocks={post.preview}/>
              </div>
            </div>
          </Link>
        </div>
      }
    </>
  )
}