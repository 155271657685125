import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Outlet } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ModalContainer } from "./ModalContainer";

export const SiteContainer = ({modal, setModal}) => {
  const modalControl = useRef(null)
  const location = useLocation()

  useEffect(()=>{
    if(location.pathname !== '/' && modal){
      setModal(null)
    }
  },[location, setModal, modal])

  useEffect(()=>{
    if(modal){
      modalControl.current.classList.remove('hidden')
      document.body.style.overflow = 'hidden'
    } else {
      modalControl.current.classList.add('hidden')
      document.body.style.overflow = ''
    }
  },[modal])

  return (
    <>
      <div className="bg-placeholderGray bg-opacity-10">
        <Header/>
        <div className="min-h-screen pt-20">
          <Outlet/>
        </div>
        <Footer/>
      </div>
      <div ref={modalControl} className="hidden">
        <ModalContainer modal={modal} setModal={setModal}/>
      </div>
    </>

  );
}