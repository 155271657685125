import { useLayoutEffect } from "react"
import { gsap } from "gsap"

export const useSimpleFadeIn = (ref, dataDependency) => {
  useLayoutEffect(()=>{
    if(ref && dataDependency && Object.keys(dataDependency).length > 0){
      gsap.set(ref.current,{opacity:0})
      gsap.to(ref.current,{opacity:1, duration:0.75})
    }
  },[ref, dataDependency])
}