import { useState, useEffect, useRef, useLayoutEffect} from "react";
import SanityClient from '../../client'
import { useSimpleFadeIn } from "../../customHooks/useSimpleFadeIn";
import {FreeOffers} from './FreeOffers'
import {ImproveSite} from './ImproveSite'
import {BuildTiers} from './BuildTiers'
import gsap from "gsap";

export const Services = ({setModal, setMessage}) => {
  const ref = useRef(null)
  const [services, setServices] = useState(null)

  useSimpleFadeIn(ref, services)

  useLayoutEffect(()=>{
    if(ref.current && window.innerWidth > 1024){
      gsap.set(ref.current,{y:window.innerHeight < window.innerWidth ? Math.floor(window.innerHeight / 4) : 0})
    }
  },[ref])

  useEffect(()=>{
    SanityClient.fetch(`*[_type == 'services']`)
    .then(data =>{
      setServices(data[0])
    })
  },[])

  return (
    <div className="backdrop-blur-sm h-screen">
      <div ref={ref}>
        {services &&
          <div className="flex justify-around flex-col lg:flex-row">
            <button className="serviceBox" onClick={ () => setModal(<FreeOffers offers={{seo:services.seoGuide, consultation:services.consultation}} setMessage={setMessage}/>)}>
              <h3 className="border-0">Free Offers</h3>
            </button>
            <button className="serviceBox" onClick={ () => setModal(<ImproveSite improvement={{improvement:services.improvement, analytics:services.analytics}} setMessage={setMessage}/>)}>
              <h3 className="border-0">Improve A Website</h3>
            </button>
            <button className="serviceBox" onClick={ () => setModal(<BuildTiers tiers={{clean:services.clean, competitive:services.competitive, complete:services.complete}} setMessage={setMessage}/>)}>
              <h3 className="border-0">Make A New Site</h3>
            </button>
          </div>
        }
      </div>
    </div>
  );
}