import { useParams } from "react-router-dom"
import { useSanityToGetPreviewsForAPage } from '../customHooks/useSanityToGetPreviewsForAPage'
import { PageNumberNavigation } from "./PageNumberNavigation"
import { PostPreview } from "./PostPreview"
import { useRef } from "react"
import {useSimpleFadeIn} from '../customHooks/useSimpleFadeIn'


export const PostPage = ({title, slugs, pageData, setPageData}) => {

  const ref = useRef(null)

  useSimpleFadeIn(ref, pageData)

  const params = useParams()

  const itemsPerPage = 15
  const startAt = (parseInt(params.pageNumber) - 1) * itemsPerPage
  const numberOfButtons = 7

  //check if page is valid
  if (!parseInt(params.pageNumber)){
    slugs = [] //if not, set to empty array, so that the page will render the search not found page and no further queries will be done to the CMS
  }

  useSanityToGetPreviewsForAPage(slugs, startAt, pageData, setPageData, params.pageNumber, itemsPerPage)

  return(
    <main>
      <h1 className="postContainerTitle">{title}</h1>
      {pageData[params.pageNumber] && 
        <div className="flex flex-col space-y-10" ref={ref}>
          {pageData[params.pageNumber].map((result, i) => 
            <div key={`${result.title}-${startAt + i}`}>
              <PostPreview post={result}/>
            </div>
          )}
          <PageNumberNavigation 
            basePageURL={`/blog/`} 
            pageLimit={Math.ceil(slugs.length / itemsPerPage)} 
            numberOfButtons={numberOfButtons}
            pageNumberText={params.pageNumber}/>
        </div>
      }
    </main>
  )
}