import { useSanityToGetSlugs } from '../../customHooks/useSanityToGetSlugs'
import {useState} from 'react'
import {Helmet} from 'react-helmet'
import { Routes, Route  } from 'react-router-dom'
import { PostPage } from '../../components/PostPage'

export const Blog = () => {

  window.scrollTo(0,0)

  const [blogSlugs, setBlogSlugs] = useState([])
  const [pageData, setPageData] = useState({}) //holds data after each page is fetched

  const fetchThisManySlugs = 500

  useSanityToGetSlugs('post', fetchThisManySlugs, setBlogSlugs)

  return (
    <>
      <Helmet>
        <title>Conor's Blog</title>
      </Helmet>
      <Routes>
        <Route path="/:pageNumber" element={
          <PostPage 
            title={"Blog"}
            slugs={blogSlugs} 
            pageData={pageData} 
            setPageData={setPageData}/>
        }/>
      </Routes>
    </>
  )
}