import { useState, useEffect, useCallback } from "react"
import  sanityClient  from '../client'
import { searchQueryAll, searchSlugsForSinglePage, byTags } from "../services/sanityQueries"
import { PostPreview } from "./PostPreview"
import Fuse from 'fuse.js'
import { makeItorator } from "../functions/makeItorator"
import { sortSanityResults } from "../functions/sortSanityResults"

export const Recomendations = ({currentThing, numberOfRecomendations}) => {

  const [recomendations, setRecomendations] = useState(null)

  const removeSelfFromResults = useCallback((results) => results.filter(content => content.slug !== currentThing.slug.current && currentThing._type !== content.itemType),[currentThing])

  useEffect(()=>{
    (async () => {
      if(currentThing){
        const searchSlug = (() => {
          let slug = currentThing.slug.current
          if(currentThing.tags !== null){
            currentThing.tags.forEach(tag => slug = `${slug} ${tag}`)
          }
          return slug
        })()
        const queryAll = await sanityClient.fetch(searchQueryAll)
        const options = {
          includeScore:true,
          keys:[
            {
              name:'title',
              weight:3
            },
            {
              name:'tags',
              wight:1
            }
          ]
        }
        const fuse = new Fuse(queryAll, options)
        const searchResults = fuse.search(searchSlug).map(result => {
          return {slug:result.item.slug.current, itemType:result.item._type}
        })
        const searchResultsMinusCurrentPost = removeSelfFromResults(searchResults)

        if(searchResultsMinusCurrentPost.length <= 0){
          const featuredResults = await sanityClient.fetch(byTags('post', 'featured', true, 5))
          const selfNotInResults = featuredResults.filter(results => results.slug.current !== currentThing.slug.current)
          setRecomendations(selfNotInResults)
        } else {
          const slugsSearchResults = searchResultsMinusCurrentPost.map(results => results.slug)
          const recomendedContent = await sanityClient.fetch(searchSlugsForSinglePage(slugsSearchResults, 0, numberOfRecomendations))
          console.log(searchSlugsForSinglePage(slugsSearchResults, 0, numberOfRecomendations))
          setRecomendations(removeSelfFromResults(sortSanityResults(slugsSearchResults, recomendedContent)))
        }
      }
    })()
  },[currentThing, numberOfRecomendations, removeSelfFromResults])

  const loadingItorator = makeItorator(numberOfRecomendations)

  return(
    <>
      {recomendations && recomendations.length >= 1 &&
      <h3 className="border-b-2 p-4 my-10">Recomendations</h3>}
      {recomendations && recomendations.length >= 1 && 
        recomendations.map((result, i) =>
        <div className="post w-full py-5" key={`${result.slug.current}-recomendation-${i}`}>
          <PostPreview post={result}/>
        </div>
        )
      }
      {recomendations === null &&
        loadingItorator.map((i) => <PostPreview post={null} key={i}/>)
      }
    </>
  )
}