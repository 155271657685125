import BlockContent from '@sanity/block-content-to-react'
import { Dot } from './../../icons/Dot'
import { Html5 } from '../../icons/Html5'
import { Css3 } from '../../icons/Css3'
import { Javascript } from '../../icons/Javascript'
import { Git } from '../../icons/Git'
import { React } from '../../icons/React'
import { Tailwindcss } from '../../icons/Tailwindcss'
import { Nodejs } from '../../icons/Nodejs'

export const General = ({content}) => {

  const size = 9

  return (
      <div className="pt-7 flex flex-col text-lg bg-white bg-opacity-80">
        <h3 className="text-center p-4 pb-1 border-b-2 mt-4 mx-4 md:mb-2 lg:mb-4">Who I am</h3>
        <ul className="md:flex pb-10 md:pb-5 list-none text-center justify-center text-xl font-semibold pl-0">
            <li className="md:inline-block pt-2">Polymath</li>
            <li className="inline-block p-3">
              <Dot size={size}/>
            </li>
            <li className="md:inline-block">Motivated</li>
            <li className="inline-block p-3">
              <Dot size={size}/>
            </li>
            <li className="md:inline-block">Self-Taught</li>
          </ul>
        <div className="skillsContainer"> 
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div className='overview'>
              <h5 className='aboutContentHeader'>Quick Overview</h5>
              <BlockContent blocks={content.overview}/>
            </div>
            <div className='education'>
              <h4 className="aboutContentHeader">Education</h4>
              <BlockContent className="educationBlockContent" blocks={content.education}/>
            </div>
          </div>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div className='soft'>
              <h5 className="aboutContentHeader">Soft Skils</h5>
              <BlockContent blocks={content.soft}/>
            </div>
            <div className='hard'>
              <h5 className="aboutContentHeader">Hard Skills</h5>
              <BlockContent blocks={content.hard}/>
            </div>
          </div>
          <div className="pb-10">
            <h5 className="aboutContentHeader text-center">Technical Skills</h5>
            <ul className="flex flex-wrap justify-center ml-0 px-0 list-none pl-0 space-y-0 py-0 pt-3">
              <li className="px-2">
                <div className="p-2"><Html5/></div>
                <h6 className="text-center">HTML 5</h6>
              </li>
              <li className="px-2">
                <div className="p-2"><Css3/></div>
                <h6 className="text-center">CSS 3</h6>
                </li>
              <li className="pl-2">
                <div className="p-2 pl-6"><Javascript/></div>
                <h6 className="text-center">JavaScript</h6>
              </li>
              <li className="px-2">
                <div className="p-2"><Git/></div>
                <h6 className="text-center">Git</h6>
              </li>
              <li className="px-2">
                <div className="p-2 pb-4"><React/></div>
                <h6 className="text-center">React</h6>
              </li>
              <li className="px-2">
                <div className="pl-6"><Tailwindcss/></div>
                <h6 className="text-center">Tailwindcss</h6>
              </li>
              <li className="px-2">
                <div className="p-2"><Nodejs/></div>
                <h6 className="text-center">Nodejs</h6>
              </li>
            </ul>
          </div>
        </div>
      </div>
  )
}