import { useParams } from "react-router-dom"

import { searchSlugsForSinglePage } from "../../services/sanityQueries"
import  sanityClient  from '../../client'
import { sortSanityResults } from '../../functions/sortSanityResults'

import { useEffect, useCallback, useRef} from 'react'

import { PostPreview } from '../../components/PostPreview'
import {PageNumberNavigation} from "../../components/PageNumberNavigation"
import { SearchNotFound } from "./SearchNotFound"
import {useSimpleFadeIn} from '../../customHooks/useSimpleFadeIn'

export const SearchPage = ({fuseSearch, pageData, setPageData}) => {

  const ref = useRef(null)

  useSimpleFadeIn(ref, pageData)

  window.scrollTo(0,0)

  const params = useParams()

  //check if page is valid
  if (!parseInt(params.pageNumber)){
    fuseSearch = [] //if not, set to empty array, so that the page will render the search not found page and no further queries will be done to the CMS
  }

  const numberOfButtons = 7
  const itemsPerPage = 15
  const startAt = (parseInt(params.pageNumber) - 1) * itemsPerPage

  const handleNewPageData = useCallback((sanityResult)=>{
    const pageDataCopy = {...pageData}
    pageDataCopy[params.pageNumber] = sortSanityResults(fuseSearch, sanityResult)
    setPageData(pageDataCopy)

  },[fuseSearch, params.pageNumber, pageData, setPageData])

  useEffect(() => {
    if (! pageData[params.pageNumber] && fuseSearch.length >= 1) {
      (async () => {
        const sanityResult = await sanityClient.fetch(
          searchSlugsForSinglePage(fuseSearch, startAt, itemsPerPage)
        )
        handleNewPageData(sanityResult)
      })()
    }

  },[handleNewPageData, params.pageNumber, pageData, fuseSearch, startAt, itemsPerPage])

  return(
    <>
    {fuseSearch.length >= 1 &&
    <main>
      <div className='postContainer bg-opacity-0' >
        <h1 className="postContainerTitle">Search Results</h1>
        <div className="flex flex-col space-y-10" ref={ref}>
          {pageData[params.pageNumber] && 
            pageData[params.pageNumber].map((result, i) => 
              <div key={`${result.title}-${startAt + i}`}>
                <PostPreview post={result}/>
              </div>
            )
          }
        </div>
      </div>
      <PageNumberNavigation 
        basePageURL={`/search/${params.searchQuery}`} 
        pageLimit={Math.ceil(fuseSearch.length / itemsPerPage)} 
        numberOfButtons={numberOfButtons}
        pageNumberText={params.pageNumber}/>
    </main>
    }
    {fuseSearch.length <= 0 &&
      <SearchNotFound/>
    }
    </>
  )
}