import { apiEvent } from './apiEvent'
import axios from 'axios'

export const sendEmail = async (email) => {

  const newEmail = new apiEvent('contactMe', email)

  try{
    const request = await axios.post(`/api/email`, newEmail)
    return request
  } catch (err){
    return err
  }
}