//used to get the required data for a post preview - used by other queries as the retrevial structure for whatever items they specify to get
const preview = 
  `title,
  _type,
  preview[]{
    ...,
    asset -> {
      ...,
      "_key": _id
    }
  },
  textColor,
  backgroundColor,
  slug,
  tags,
  link,
  projectType,
    mainImage{
    asset->{
      _id,
      url,
    },
    alt,
  },`

//gets acending or decending values in GROQ from a boolean
const recency = (mostRecent) => mostRecent ? 'desc' : 'asc'

//limits the number of posts to get in GROQ
const queryLimit = (limit) => limit ? `[0...${limit}]` : `[]`

//used to get previews for the projects and blog pages
const generalQuery = (type, mostRecent, limit) => (
  `*[_type == '${type}'] | order(_createdAt ${recency(mostRecent)}){
    ${preview}}${queryLimit(limit)}`
)

//generates a GROQ string for an arbitrary number of tags
const multipleTags = (tags) => {
  const addSyntax = (tag) => ` || '${tag}' in tags[]`
  let string = ``
  if(Array.isArray(tags)){
    const andTag = tags.map(tag => addSyntax(tag))
    andTag.forEach(tag => string = `${string}${tag}`)
  } else {
    string = addSyntax(tags)
  }
  return string
}

//search by tags - used by the homepage to display most recent projects/blog posts of a specific type
const byTags = (type, tags, mostRecent, limit) => (
  `*[_type == '${type}' ${multipleTags(tags)}] 
  | order(_createdAt ${recency(mostRecent)}){
    ${preview}}${queryLimit(limit)}`
)

//gets comments for a post
const comments = (postId, limit) => (
  `*[_type == 'comment' && post._ref == '${postId}'] | order(_createdAt asc){
    name,
    comment,
    _id,
    _createdAt,
    pinned
  }${queryLimit(limit)}`
)

const singlePost = (slug) => (
  `*[slug.current == "${slug}" && _type == "post"]{
    title,
    _type,
    slug,
    tags,
    _id,
    commentsAllowed,
    publishedAt,
    body[]{
      ...,
      asset -> {
        ...,
        "_key": _id
      }
    },
    "name": author->name,
    "authorImage": author->image,
    "bio": author->bio,
  }`
)

const singleProject = (slug) => (
  `*[slug.current == "${slug}" && _type == "project"]{
    title,
    _type,
    slug,
    tags,
    link,
    date,
    projectType,
    description,
    publishedAt,
    mainImage{
      asset->{
        _id,
        url,
      },
      alt,
    },
    "name": author->name,
    "authorImage": author->image,
    "bio": author->bio,
  }`
)

//used bu Fuse.js for fuzzy search weighting of results
const searchQueryAll = 
`*[_type == "project" || _type == "post"]{
  title,
  _type,
  slug{
    current
  },
  tags
}`

const generalPostQuery = (type, mostRecent, limit) => {
  return(
    `*[_type == '${type}'] | order(_createdAt ${recency(mostRecent)}){
      slug{
        current
      },
      }${queryLimit(limit)}`
  )
}

//used to get all the slugs for a single page of the search results
const searchSlugsForSinglePage = (slugs, startAt, itemsPerPage) => {
  const multipleSlugs = (() => {
    let string = ''
    for (let i = startAt; i <= startAt + itemsPerPage - 1 && i < slugs.length; i++){
      if(i === startAt){
        string = `slug.current == '${slugs[i]}'`
      } else {
        string = `${string} || slug.current == '${slugs[i]}'`
      }
    }
    return string
  })()
  return(
    `*[${multipleSlugs}]{
      ${preview}
    }`
  )
}

export {
  generalQuery, 
  byTags,
  comments, 
  singlePost,
  singleProject,
  searchQueryAll,
  generalPostQuery,
  searchSlugsForSinglePage
}