import {Github} from './../icons/Github'
import {Linkedin} from './../icons/Linkedin'
import {Gmail} from './../icons/Gmail'
import {Youtube} from './../icons/Youtube'
import {Tiktok} from './../icons/Tiktok'

export const Social = ({fill, innerFill}) => {
  return(
      <ul className="flex content-center ml-0 pl-0 space-y-0 py-0">
        <li className="inline-block mx-1 sm:mx-2 md:mx-4">
          <a href="https://github.com/conorjamesward" target="_blank" rel="noreferrer" className="border-0">
            <Github fill={fill}/>
          </a>
        </li>
        <li className="inline-block mx-1 sm:mx-3 md:mx-5">
          <a href="https://www.linkedin.com/in/conor-ward-01000011-01110111/" target="_blank" rel="noreferrer" className="border-0">
            <Linkedin fill={fill} innerfill={innerFill}/>
          </a>
        </li>
          <li className="inline-block mx-1 sm:mx-3 md:mx-5">
          <a href="mailto:sales@conorjamesward.com" target="_blank" rel="noreferrer" className="border-0">
            <Gmail fill={fill}/>
          </a>
        </li>
        <li className="inline-block mx-1 sm:mx-3 md:mx-5">
          <a href="https://www.youtube.com/channel/UCtqy7m-QjCjYS-CPkY3cstg/featured" target="_blank" rel="noreferrer" className="border-0">
            <Youtube fill={fill} innerFill={innerFill}/>
          </a>
        </li>
        <li className="inline-block mx-1 sm:mx-3">
          <a href="https://www.tiktok.com/@conorlearnscode?lang=en" target="_blank" rel="noreferrer" className="border-0">
            <Tiktok fill={fill}/>
          </a>
        </li>
      </ul>
  )
}