import { useState, useEffect, useRef } from "react";
import SanityClient from '../../client'
import { useSimpleFadeIn } from "../../customHooks/useSimpleFadeIn";
import { General } from "./General";

export const About = () => {
  const ref = useRef(null)
  const [content, setContent] = useState(null)

  useSimpleFadeIn(ref, content)

  useEffect(()=>{
    SanityClient.fetch(`*[_type == 'about']`)
    .then(data =>{
      setContent(data[0])
    })
  },[])

  return (
    <div ref={ref} className="opacity-0 backdrop-blur">
      {content && <General content={content}/>}
    </div>
  );
}