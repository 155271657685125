import BlockContent from "@sanity/block-content-to-react"
import { useNavigate } from "react-router-dom";

export const BuildTiers = ({tiers, setMessage}) => {
  const navigate = useNavigate()

  return (
    <div className="modalContainer">
      <button className="modalComponent md:w-1/4"
          onClick={
            () => {
              setMessage({subject:'New Site', message:`I'm interested in your Clean Tier`})
              navigate('/contact',{replace:false})
            }
          }>
        <BlockContent blocks={tiers.clean}/>
      </button>
      <button className="modalComponent md:w-1/4"
          onClick={
            () => {
              setMessage({subject:'New Site', message:`I'm interested in your Competative Teir`})
              navigate('/contact',{replace:false})
            }
          }>
        <BlockContent blocks={tiers.competitive}/>
      </button>
      <button className="modalComponent md:w-1/4"
          onClick={
            () => {
              setMessage({subject:'New Site', message:`I'm interested in your Complete Tier`})
              navigate('/contact',{replace:false})
            }
          }>
        <BlockContent blocks={tiers.complete}/>
      </button>
    </div>
  );
}