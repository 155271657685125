import { useState, useRef, useEffect } from 'react'
import { sendEmail } from '../../services/sendEmail'
import { useSimpleFadeIn } from '../../customHooks/useSimpleFadeIn'
import { Helmet } from 'react-helmet'


export const ContactMe = ({initalMessage}) => {

  const ref = useRef(null)

  useSimpleFadeIn(ref, true)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const blankForm = (initalMessage) => {
    return {
      name:'',
      email:'',
      phone:'',
      subject: initalMessage ? initalMessage.subject : '',
      message: initalMessage ? initalMessage.message : ''
    }
  }

  const requireText = "Please fill out the required fields*"
  const postErrorText = "Error, please email: sales@conorjamesward.com"

  const [message, setMessage] = useState(blankForm(null))

  useEffect(()=>{
    setMessage(blankForm(initalMessage))
  },[initalMessage])

  const [errorText, setErrorText] = useState(null)

  const blankLine = new RegExp('[a-zA-Z0-9]')
  const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)

  const requireName = useRef()
  const nameLabel = useRef()

  const requireEmail = useRef()
  const emailLabel = useRef()
  const requireSubject = useRef()
  const subjectLabel = useRef()
  const requireMessage = useRef()
  const messageLabel = useRef()

  const errorMessage = useRef()
  const successMessage = useRef()

  const colorControl = () => {
        //color for incorrect input
        if(!blankLine.test(message.name)){
          requireName.current.classList.replace('standardInput', 'inputRequired')
          nameLabel.current.classList.replace('labelRequired', 'labelMissing')
        }
        if (!emailRegex.test(message.email)){
          requireEmail.current.classList.replace('standardInput', 'inputRequired')
          emailLabel.current.classList.replace('labelRequired', 'labelMissing')
        }
        if (!blankLine.test(message.subject)){
          requireSubject.current.classList.replace('standardInput', 'inputRequired')  
          subjectLabel.current.classList.replace('labelRequired', 'labelMissing')
        }
        if (!blankLine.test(message.message)){
          requireMessage.current.classList.replace('standardInput', 'inputRequired')
          messageLabel.current.classList.replace('labelRequired', 'labelMissing')
        }
    
        //color for correct input
        if(blankLine.test(message.name)){
          requireName.current.classList.replace('inputRequired', 'standardInput')
          nameLabel.current.classList.replace('labelMissing', 'labelRequired')
        }
        if (emailRegex.test(message.email)){
          requireEmail.current.classList.replace('inputRequired', 'standardInput') 
          emailLabel.current.classList.replace('labelMissing', 'labelRequired')
        }
        if (blankLine.test(message.subject)){
          requireSubject.current.classList.replace('inputRequired', 'standardInput')
          subjectLabel.current.classList.replace('labelMissing', 'labelRequired')
        }
        if (blankLine.test(message.message)){
          requireMessage.current.classList.replace('inputRequired', 'standardInput')
          messageLabel.current.classList.replace('labelMissing', 'labelRequired')
        }
  }

  const sendMessage = (e) => {
    e.preventDefault()

    colorControl()

    if (blankLine.test(message.name) && emailRegex.test(message.email) && blankLine.test(message.subject) && blankLine.test(message.message)){

      (async () => {
        const messageStatus = await sendEmail(message)
        try{
          if(messageStatus.status === 200){
            errorMessage.current.classList.replace('block','hidden')
            successMessage.current.classList.replace('hidden','block')
            setMessage(blankForm(null))
            setTimeout(()=>{
              successMessage.current.classList.replace('block', 'hidden')
            },5000)
          } else throw new Error('message could not be sent')
        } catch(err){
          setErrorText(postErrorText)
          errorMessage.current.classList.replace('hidden', 'block')
        }
      })()
    } else {
      setErrorText(requireText)
      errorMessage.current.classList.replace('hidden', 'block')
    }
  }

  const handleChange = (e) => {
    setMessage({...message, [e.target.name]:e.target.value})
  }


  const [textWidth, setTextWidth] = useState(null)
  
  const calcTextWidth = () => {
    
    const width = window.innerWidth

    setTextWidth(Math.floor(width / 19))

  }

  window.addEventListener('resize', calcTextWidth)

  useEffect(()=>{
    calcTextWidth()
  },[])

  return (
    <> 
      <Helmet>
        <title>Contact Conor</title>
      </Helmet>    
      <div id="contactMe" className='space-y-4 px-5 pt-32' ref={ref}>
            <h3 className="text-center text-lg md:text-xl lg:text-2xl font-bold">Please feel free to reach out reguarding any questions or inqueries</h3>
            <h4 ref={successMessage}
            className="text-center text-rightGreenB border-2 border-x-rightGreenB bg-rightGreenA bg-opacity-50 py-2 my-8 mx-20 hidden">
              Message Sent!
            </h4>
            <h4 ref={errorMessage}
            className="inputRequired text-wrongRedA text-center py-2 my-8 mx-20 hidden">
              {errorText}
            </h4>
            <form className="xl:w-3/4 mx-auto sm:px-2" onSubmit={sendMessage}>
              <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <div className="py-4 flex flex-col">
                  <label ref={nameLabel} className="labelRequired" htmlFor="name">Name*</label>
                  <input ref={requireName}
                  className="standardInput orangeFocus" id="name" type="text" name="name" onChange={handleChange} value={message.name}/>
                </div>
                <div className="py-4 flex flex-col">
                  <label ref={emailLabel} className="labelRequired" htmlFor="email">Email*</label>
                  <input ref={requireEmail}
                  className="standardInput orangeFocus" id="email" type="text" name="email" onChange={handleChange} value={message.email}/>
                </div>
                <div className="py-4 flex flex-col">
                  <label className="contactLabel" htmlFor="phone">Phone</label>
                  <input className="standardInput orangeFocus" id="phone" type="tel" name="phone" onChange={handleChange} value={message.phone}/>
                </div>
              </div>
              <div className="flex place-content-center py-6">
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col py-8">
                    <label ref={subjectLabel} className="labelRequired">Subject*</label>
                    <input ref={requireSubject}
                    className="standardInput orangeFocus" id="subject" type="text" name="subject" onChange={handleChange} value={message.subject}/>
                  </div>
                  <label ref={messageLabel} className="labelRequired" htmlFor="message">Message*</label>
                  <textarea ref={requireMessage}
                  className="standardInput orangeFocus" id="message" rows="10" cols={textWidth} name="message" onChange={handleChange} value={message.message}></textarea>
                </div>
              </div>
              <div className="buttonContainer">
                <input className="standardButton orangeHover" type="submit" value="Send Message"/>
              </div>
            </form>
          </div>
        </>
  )
}