import { useState, useEffect } from "react"
import  sanityClient  from '../../client'
import { byTags } from "../../services/sanityQueries"
import { PostPreview } from "../../components/PostPreview"
import { SearchBar } from "./../../components/SearchBar"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/all"
import doubleDown from "../../icons/icons8-double-down-50.png"

export const SearchNotFound = () => {

  const postType = "post"
  const sampleNumber = 6

  const [previewList, setPreviewList] = useState(null)

  gsap.registerPlugin(ScrollToPlugin)

  const scrollTo = () => {
    gsap.to(window, { duration:1.2, scrollTo:{y:"#featured"}})
  }
  


  useEffect(()=>{
    (async () =>{
      const sanityPreviews = await sanityClient.fetch(byTags(postType, ["featured", "search"], true, 6))
      setPreviewList(sanityPreviews)
    })()
  },[postType, sampleNumber])

  return(
    <main className="bg-placeholderGray bg-opacity-10">
      <div className="p-5 pb-32">
        <div className="h-screen">
          <div className="space-y-4 mt-32">
            <h1 className="text-center text-4xl font-extrabold">I'm sorry, nothing could be found.</h1>
            <h2 className="text-center text-4xl font-extrabold">Try again?</h2>
          </div>
          <div className="py-5 mx-auto md:w-3/4 lg:w-1/2 mb-48">
            <SearchBar autoFocus={true}/>
          </div>
          <h3 className="text-center font-bold text-2xl">Or maybe something recent might interest you</h3>
          <div className="buttonContainer"> 
            <button className="font-bold text-2xl animate-bounce p-3" onClick={scrollTo}>
              <img className="w-12 h-12" src={doubleDown} alt="double down"/>
            </button>
          </div>
          
        </div>
        {previewList &&
          <div id="featured" className="postContainer bg-opacity-0">
            {
              previewList.map((preview, i) => 
                <div className="post w-2/3" key={`preview-${i}`}>
                  <PostPreview post={preview}/>
                </div>
              )
            }
          </div>}
      </div>
    </main>
  )
}