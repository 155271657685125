export const sortSanityResults = (fuseResults, sanityResults) => {
  const sortedSanityResults = []
  if (sanityResults.length !== 0){
    fuseResults.forEach(item => {
      sanityResults.forEach((result, i) => {
        if(result.slug.current === item){
          sortedSanityResults.push(result)
          sanityResults.splice(i, 1)
          return
        }
      })
    });
  }
  return sortedSanityResults
}