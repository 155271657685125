import resumeImage from './../../icons/icons8-set-as-resume-50.png'
import resume from "../../../Conor's-Online-Resume.pdf"
import ReactTooltip from "react-tooltip";

export const Resume = () => (
  <>
    <a href={resume} className="hover:scale-110 duration-100 m-10 animate-bounce">
      <img src={resumeImage} alt="Resume Icon" data-tip data-for="resumeTip" className='p-2 mx-auto'/>
    </a>
    <ReactTooltip id="resumeTip" place="top" effect="solid">
      Download My Resume
    </ReactTooltip>
  </>
)