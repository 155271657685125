import { SearchBar } from "../../components/SearchBar";
import gsap from "gsap"
import { useRef, useEffect } from "react";
import { Recomendations } from "../../components/Recomendations";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export const FourZeroFour = () => {
  const ref = useRef(null)
  const location = useLocation()

  useEffect(() => {
    const gsapSelector = gsap.utils.selector(ref)
    const timeline = gsap.timeline()
    timeline.from(gsapSelector('#header'), {yPercent: 100, opacity:0,})
    timeline.from(gsapSelector('#searchBar'), {yPercent: 100, opacity:0, }, '-=10%')
    timeline.from(gsapSelector('#recomendations'), {yPercent: 100, opacity:0, }, '-=10%')
  },[])

  return (
    <>    
      <Helmet>
        <title>Conor's Lost</title>
      </Helmet>
      <div className="pt-20" ref={ref}>
        <div className="w-3/4 mx-auto overflow-hidden">
          <h1 id="header" className="text-center text-2xl md:text-3xl xl:text-4xl font-extrabold py-24">I'm Sorry, I can't find that. Maybe I can try and find something else?</h1>
          <div id="searchBar" className="pb-24">
            <SearchBar autoFocus={true}/>
          </div>
          <div id="recomendations" className="py-24">
            <Recomendations currentThing={{slug:{current:location.pathname}, tags:null, _type:'post'}} numberOfRecomendations={5}/>
          </div>
        </div>
      </div>
    </>
  );
}