import { NavLink } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { SearchBar } from "./SearchBar"
import { useState, useRef, useEffect} from "react"
import { Hamburger } from "../icons/Hamburger"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/all"

export const Header = () => {

  const location = useLocation()
  const stillHomePage = ['/about']

  const [showMenu, setShowMenu] = useState(false)

  const menu = useRef()
  const search = useRef()
  const ref = useRef(null)

  const handleShowMenu = () => {
    if(showMenu){
      menu.current.classList.replace('flex', 'hidden')
    } else {
      menu.current.classList.replace('hidden', 'flex')
    }
    setShowMenu(!showMenu)
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    let percent = -110
    const showAnim = gsap.from(ref.current, { 
      yPercent: percent,
      paused: true,
      duration: 0.2
    }).progress(1);
    
    ScrollTrigger.create({
      start: "top top",
      end: 99999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse()
      }
    });
  },[])

  return(
    <header ref={ref} id="header" className={`w-full fixed z-50 border-b-2 border-orangeA backdrop-filter backdrop-blur-sm bg-white bg-opacity-70`}>
      <div
      className="flex md:hidden justify-between p-2">
        <div className="cursor-pointer p-1" onClick={handleShowMenu}>
          <Hamburger/>
        </div>
        <div>
          <SearchBar/>
        </div>
      </div>
      <div ref={menu} className="hidden md:flex flex-col-reverse md:flex-row md:justify-between">
        <div ref={search} className={`hidden md:block`}>
          <SearchBar/>
        </div>
        <ul className="headerNav">
          <NavLink to="/" 
            className={`${window.innerWidth > 768 ? "headerNavLink" : "mobileHeaderLink"} 
              ${stillHomePage.find(subpage => subpage === location.pathname) ? 'active' : ''}`}>
            Home
          </NavLink>
          <NavLink to="/projects/1" className={window.innerWidth > 768 ? "headerNavLink" : "mobileHeaderLink"}>
            Projects
          </NavLink>
          <NavLink to="/blog/1" className={window.innerWidth > 768 ? "headerNavLink" : "mobileHeaderLink"}>
            Blog
          </NavLink>
          <NavLink to="/contact" className={window.innerWidth > 768 ? "headerNavLink" : "mobileHeaderLink"}>
            Contact
          </NavLink>
        </ul>
      </div>
    </header>
  )
}