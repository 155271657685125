import BlockContent from "@sanity/block-content-to-react"
import { useNavigate } from "react-router-dom";

export const ImproveSite = ({improvement, setMessage}) => {
  const navigate = useNavigate()
  
  return (
    <div className="modalContainer">
      <button className="modalComponent md:1/3"
          onClick={
            () => {
              setMessage({
                subject:'Site Checkup & Improvement',
                message:`I have a site already. I'm hoping you can improve it.`
              })
              navigate('/contact', {replace:false})
            }
          }>
        <BlockContent blocks={improvement.improvement}/>
      </button>
      <button className="modalComponent md:1/3"
          onClick={
            () => {
              setMessage({
                subject:'Analytics, Insights, & Performance',
                message:'I need analytics, I want to be able to manage my website, and see what my customers are doing.'
              })
              navigate('/contact', {replace:false})
            }
          }>
        <BlockContent blocks={improvement.analytics}/>
      </button>
    </div>
  );
}