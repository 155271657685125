import {useState, useEffect} from 'react'
import { singlePost } from '../../services/sanityQueries'
import SanityClient from '../../client'
import { Recomendations } from '../../components/Recomendations'
import Helmet from 'react-helmet'
import { Author } from '../../components/Author'
import { CustomBlockContent } from '../../sanityExtensions/CustomBlockContent'
import { useParams } from 'react-router-dom'

export const SingleBlog = () => {

  const [singlePostData, setSinglePostData] = useState(null)
  const params = useParams()

  useEffect(() => {
    window.scrollTo(0,0)
    SanityClient.fetch(singlePost(params.blogId))
    .then((post) => setSinglePostData(post[0]))
  },[params])

  return (
    <>
        <main className="blogPost">
          {singlePostData !== null &&
          <>
          <Helmet>
            <title>{singlePostData.title}</title>
          </Helmet>
            <div className="blockContent blockContentBackground pb-20">
            <br/>
            <h1>{singlePostData.title}</h1>
            <Author singleData={singlePostData}/>
            <br/>
            <CustomBlockContent
              body={singlePostData.body}/>
            </div>
            <Recomendations 
            currentThing={singlePostData} 
            numberOfRecomendations={5}/>
          </>
          }
        </main>
  </>
  )
}