import SanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'

export const Author = ({singleData}) => {

  const builder = imageUrlBuilder(SanityClient);
  const urlFor = (source) => (builder.image(source))
  const publishDate = new Date(singleData.publishedAt ? singleData.publishedAt : singleData.date)

    return (
      <div className="flex justify-start">
        <img src={urlFor(singleData.authorImage).url()} alt="Author"
        className="w-10 h-10 p-0 mx-2 my-auto"/>
        <div className="py-2 author">
          <p>Published: {`${publishDate}`}</p>
          <p>By: <strong className="font-semibold">{singleData.name}</strong></p>
        </div>
      </div>
    )
}