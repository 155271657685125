import { useRef, useLayoutEffect } from "react";
import { Close } from "./icons/Close";
import gsap from "gsap";

export const ModalContainer = ({setModal, modal}) => {

  const ref = useRef(null)

  useLayoutEffect(()=>{
    if(ref.current){
      gsap.set(ref.current,{y:window.innerHeight < window.innerWidth ? Math.floor(window.innerHeight / 4) : 0})
    }
  },[ref])

  window.addEventListener('keydown', event => {
    event.key === 'Escape' && setModal(null)
  })
  
  return (
    <div className="flex-col bg-placeholderGray backdrop-blur-sm bg-opacity-60 w-full h-screen fixed top-0 overflow-y-scroll z-50" onClick={() => setModal(null)}>
      <div className="flex justify-end">
        <button className="cursor-pointer p-2 bg-orangeA rounded-md m-1 bg-opacity-80" onClick={() => setModal(null)}>
          <Close/>
        </button>
      </div>
      <div ref={ref}>
        {modal}
      </div>
    </div>
  );
}