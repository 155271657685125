import { useEffect, useState, useRef} from "react"
import { Outlet } from "react-router-dom"
import {Helmet} from "react-helmet"

import { Resume } from "./Resume"
import SanityClient from '../../client'
import { MakeAnInquery } from "./MakeAnInquery"
import { TechSkills } from "./TechSkills"
import gsap from "gsap"

export const Home = () => {
  const [greeting, setGreeting] = useState(null)
  const ref = useRef(null)

  //!history from some non-home address to place effect

  useEffect(() => {
    SanityClient.fetch(`*[_type=="author"]{
      greeting
    }`)
    .then(greetingMessage => {
      setGreeting(greetingMessage[0].greeting)
      }
    )
    const gsapSelector = gsap.utils.selector(ref)
    const timeline = gsap.timeline()
    timeline.from(gsapSelector('#introduction'), {yPercent: 50, opacity:0, }, '-=20%')
    timeline.from(gsapSelector('#techSkills'), {yPercent: 50, opacity:0, }, '-=20%')
    timeline.from(gsapSelector('#inquery'), {yPercent: 50, opacity:0, }, '-=20%')
    timeline.from(gsapSelector('#theRest'), {yPercent: 50, opacity:0, }, '-=20%')
  },[])
  
  return(
    <>
    <Helmet>
      <title>Conor's Site</title>
    </Helmet>
    <div ref={ref}>
      <div id="introduction" className="lg:flex-row pt-10">
        <div className="w-full">
          <h1 className="
          h-72
          text-2xl pt-20
          lg:text-3xl lg:p-10
          xl:text-4xl xl:p-15
          xxl:text-5xl xxl:p-20
          font-extrabold text-center">{greeting}</h1>
        </div>
        <div id="techSkills">
          <TechSkills/>
        </div>
        <div id="inquery" className="flex flex-col md:flex-row justify-center p-10">
          <Resume/>
          <MakeAnInquery/>
        </div>
        <div className="theRest">
          <Outlet/>
        </div>
      </div>
    </div>
    </>
  )
}