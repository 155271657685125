// import { Routes, Route } from "react-router-dom";
// import {Helmet} from 'react-helmet'

// import {Home} from './pages/home/Home'
// import {Projects} from './pages/project/Projects'
// import {Blog} from './pages/blog/Blog'
// import { SingleBlog } from "./pages/blog/SingleBlog";
// import { SingleProject } from "./pages/project/SingleProject";
// import {Search} from './pages/search/Search'
// import { HeaderFooterFrame } from "./components/HeaderFooterFrame";
// import { FourZeroFour } from "./pages/noMatch/FourZeroFour";
// import { ContactMe } from "./pages/contactMe/ContactMe";
// import { useState } from "react";


// const App = () => {
//   const [message, setMessage] = useState(null)
  
//   return (
//     <div className="font-poppins">
//       <Helmet>
//         <html lang="en"/>
//         <link rel="preconnect" href="https://fonts.googleapis.com"/>
//         <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
//         <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap" rel="stylesheet"></link>
//       </Helmet>
//       <Routes>
//         <Route element={<HeaderFooterFrame/>}>
//           <Route exact path="/" element={<Home setMessage={setMessage}/>}/>
//           <Route path="projects/*" element={<Projects/>}/>
//           <Route exact path="/projects/view/:projectId" element={<SingleProject/>}/>
//           <Route path ="blog/*" element={<Blog/>}/>
//           <Route exact path="/blog/view/:blogId" element={<SingleBlog/>}/>
//           <Route path ="search/:searchQuery/*" element={<Search/>}/>
//           <Route path="/contact" element={<ContactMe initalMessage={message}/>}/>
//           <Route path ="/*" element={<FourZeroFour/>}/>
//         </Route>
//       </Routes>
//     </div>
//   );
// }

import { AltApp } from "./alt/AltApp";

const App = () => {
  return(
    <AltApp/>
  )
}

export default App;
