import inqueryImage from '../../icons/icons8-new-message-50.png'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from "react-tooltip";

export const MakeAnInquery = () => {
  const navigate = useNavigate()
  return (
    <>
      <button onClick={() => navigate('/contact', {replace: false})} className='hover:scale-110 duration-100 m-10 animate-bounce'>
        <img src={inqueryImage} alt="Send A Message Icon" data-tip data-for="inqueryTip" className='p-2 mx-auto'/>
      </button>
      <ReactTooltip id="inqueryTip" place="top" effect="solid">
        Make An Inquery
      </ReactTooltip>
    </>
  )
}