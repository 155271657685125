import { useState, useEffect } from "react"
import sanityClient from '../../client'
import { singleProject } from "../../services/sanityQueries"
import Helmet from 'react-helmet'
import { Author } from "../../components/Author"
import BlockContent from "@sanity/block-content-to-react"
import { useParams } from "react-router-dom"

export const SingleProject = () => {

  const [singleProjectData, setSingleProjectData] = useState(null)
  const params = useParams()

  useEffect(() => {
    window.scrollTo(0,0)
    sanityClient.fetch(singleProject(params.projectId))
    .then((post) => setSingleProjectData(post[0]))
    .catch(console.error)
  },[params])
  
  return (
    <>
      <div className="">
        {singleProjectData && 
          <>
            <Helmet>
              <title>{singleProjectData.title}</title>
            </Helmet>
            <main className="postContainer">
              <div className="blockContent blockContentBackground xl:w-2/3">
                <br/>
                <h1 className="">{singleProjectData.title}</h1>
                <br/>
                <div className="flex flex-wrap-reverse lg:grid lg:grid-cols-2 lg:gap-4">
                  <div>
                    <div className="mb-5">
                      <Author singleData={singleProjectData}/>
                    </div>
                    <BlockContent blocks={singleProjectData.description}/>
                    {singleProjectData.link && 
                      <div className="p-5 place-content-center">
                        <a href={singleProjectData.link}
                        id="anchorLikeButton">Take A Look</a>
                      </div>}
                  </div>
                  {singleProjectData.mainImage &&
                    <div>
                      <img src={singleProjectData.mainImage.asset.url} alt="Project Preview" className="p-0 m-0"/>
                  </div>}
                </div>
              </div>
            </main>
          </>
        }
      </div>
    </>
  )
}