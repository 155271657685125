import BlockContent from "@sanity/block-content-to-react"
import { useNavigate } from "react-router-dom";

export const FreeOffers = ({offers, setMessage}) => {
  const navigate = useNavigate()
  
  return (
    <div className="modalContainer">
      <button className="modalComponent md:1/3"
          onClick={
            () => {
              setMessage({subject:'Free Offer', message:'I Would Like the SEO & Site Design Guide'})
              navigate('/contact', {replace:false})
            }
          }>
        <BlockContent blocks={offers.seo}/>
      </button>
      <button className="modalComponent md:1/3"
          onClick={
            () => {
              setMessage({subject:'Free Offer', message:'I Would Like A Free Consultation'})
              navigate('/contact', {replace:false})
            }
          }>
        <BlockContent blocks={offers.consultation}/>
      </button>
    </div>

  );
}