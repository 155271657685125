import { useState, useEffect, useRef, useCallback, useMemo} from "react"
import { MagnifyingGlass } from '../icons/MagnifyingGlass'

export const SearchBar = ({autoFocus = false}) => {

  const [currentSearchText, setCurrentSearchText] = useState(null)
  const [searchURL, setSearchURL] = useState(null)
  const searchQuery = useRef()
  const self = useRef(null)
  const blankLine = useMemo(() => new RegExp('[a-zA-Z0-9]'),[])

  const updateSearch = (e) => {
    setCurrentSearchText(e.target.value)
  }

  const search = useCallback((e) => {
    e.preventDefault()
    if(blankLine.test(currentSearchText)){
      setSearchURL(`${currentSearchText.replace(/\s+/g, '-').toLowerCase()}/1`)
      searchQuery.current.click()
    }
  },[currentSearchText, blankLine])

  const enter = useCallback((e) => {
    if(e.key === 'Enter' && e.target === self.current){
      search(e)
    }
  },[search])

  useEffect(()=>{
    if(blankLine.test(currentSearchText)){
      window.addEventListener('keydown', enter)
    }
  },[currentSearchText, enter, blankLine])

  useEffect(()=>{
    setCurrentSearchText('')
  },[])

  return(
    <div className="relative flex flex-wrap items-stretch p-2 px-4">
      <span className="z-10 h-full absolute text-center text-black bg-transparent rounded items-center justify-center w-8 pl-3 py-3">
        <span onClick={search} className="cursor-pointer">
          <MagnifyingGlass/>
        </span>
      </span>
      <input ref={self} onChange={updateSearch} name="searchBar" type="text" placeholder="Search" className="placeholder-black font-semibold hover:placeholder-white focus:placeholder-white text-black relative border-2 border-black outline-none  w-full pl-10 py-2 bg-orangeA bg-opacity-0 backdrop-filter backdrop-blur-sm hover:bg-opacity-60 focus:bg-opacity-60" autoFocus={autoFocus}/>
      <a ref={searchQuery} href={`/search/${searchURL}`} className="hidden">HiddenSearch</a>
    </div>
  )
}