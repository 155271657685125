import { Social } from "./Social"

export const Footer = () => {
  const date = new Date()
  return (
    <footer className="h-96 p-5 pt-10 w-full">
      <div className="flex justify-center border-t-2 border-b-2 h-1/4 p-5">
        <Social fill={"#000000"} innerFill={'#EEEEEE'}/>
      </div>
      <div className="flex-col">
          <h6 className="text-center p-6">Site designed & built by <strong><a href="https://www.linkedin.com/in/conor-ward-01000011-01110111/" target="_blank" rel="noreferrer" className="border-0">Conor</a></strong>, icons from <strong><a href="https://icons8.com/"target="_blank" rel="noreferrer" className="border-0">Icons8</a></strong>.</h6>
          <p className="text-center p-6">&copy; {date.getFullYear()}. All Rights Reserved</p>
        </div>
    </footer>
  )
}