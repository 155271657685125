import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import {SiteContainer} from './SiteContainer'
import { Home } from "./pages/home/Home";
import {Blog} from "./pages/blog/Blog"
import {Projects} from './pages/projects/Projects'
import {ContactMe} from './pages/contactMe/ContactMe'
import {Search} from './pages/search/Search'
import {FourZeroFour} from './pages/noMatch/FourZeroFour'
import { HomeNav } from "./pages/home/HomeNav";
import { Services } from "./pages/home/Services"
import {About} from './pages/home/About'
import { SingleProject } from "./pages/projects/SingleProject";
import { SingleBlog } from "./pages/blog/SingleBlog";

export const AltApp = () => {
  const [message, setMessage] = useState(null)
  const [modal, setModal] = useState(null)

  return (
    <Routes>
      <Route element={<SiteContainer modal={modal} setModal={setModal}/>}>
        <Route path="/" element={<Home/>}>
          <Route element={<HomeNav/>}>
            <Route index element={<Services setModal={setModal} setMessage={setMessage}/>}/>
            <Route path="about" element={<About/>}/>
          </Route>
        </Route>
        <Route path="blog/*" element={<Blog/>}/>
        <Route exact path="/blog/view/:blogId" element={<SingleBlog/>}/>
        <Route path="projects/*" element={<Projects/>}/>
        <Route exact path="/projects/view/:projectId" element={<SingleProject/>}/>
        <Route path="contact" element={<ContactMe initalMessage={message}/>}/>
        <Route path ="search/:searchQuery/*" element={<Search/>}/>
        <Route path ="/*" element={<FourZeroFour/>}/>
      </Route>
    </Routes>
  );
}