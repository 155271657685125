import { Html5 } from '../../icons/Html5'
import { Css3 } from '../../icons/Css3'
import { Javascript } from '../../icons/Javascript'
import { Git } from '../../icons/Git'
import { React } from '../../icons/React'
import { Tailwindcss } from '../../icons/Tailwindcss'
import { Nodejs } from '../../icons/Nodejs'

export const TechSkills = () => (
  <div className="text-techTextActive">
    <h2 className="text-center text-xl md:text-2xl font-bold">Technology I currently work with</h2>
    <div className="flex
    justify-center">
      <ul className="flex justify-center flex-wrap list-none px-0 ml-0 gap-4">
        <li className="p-2 pt-4">
          <Html5/>
        </li>
        <li className="p-2">
          <Css3/>
        </li>
        <li className="p-2 ">
          <Javascript/>
        </li>
        <li className="p-2">
          <Git/>
        </li>
        <li className="p-2 pt-3 ">
          <React/>
        </li>
        <li>
          <Tailwindcss/>
        </li>
        <li className="p-2">
          <Nodejs/>
        </li>
      </ul>
    </div>
  </div>
)